import React from 'react'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'
import MapMarker from './map-marker'
import { StyledMapContainer } from './styledComponents'

const GoogleMap = ({ lat, lng }) => {
  return (
    <StyledMapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAP_API_KEY }}
        defaultCenter={{ lat, lng }}
        defaultZoom={11}
      >
        <MapMarker lat={lat} lng={lng} />
      </GoogleMapReact>
    </StyledMapContainer>
  )
}

GoogleMap.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
}

export default GoogleMap
