import styled from 'styled-components'
import { spacing, device } from '../../../utils/mixins'
import Author from '../../author'
import Blockquote from '../../blockquote'

export const StyledContainer = styled.address`
  display: flex;
  flex-direction: column;
  font-style: normal;
  margin-top: ${spacing(3)};
  flex: 0 0 100%;

  @media ${device.tabletLand}, ${device.desktop} {
    order: 2;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: ${spacing(2)};
  }

  .title {
    font: 400 18px/25px Tenor, sans-serif;
    margin: 0;

    @media ${device.tabletLand}, ${device.desktop} {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .phones-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .subtitle,
  .phones-list li,
  .email {
    font-size: 14px;
    line-height: 20px;
    margin: ${spacing(2, 0, 0)};
    white-space: break-spaces;

    @media ${device.tabletLand}, ${device.desktop} {
      font-size: 18px;
      line-height: 32px;
    }

    @media ${device.desktop} {
      white-space: normal;
    }
  }

  .email {
    color: #0074b8;
    text-decoration: none;
  }
`

export const StyledAuthor = styled(Author)`
  display: none;

  @media ${device.tabletLand}, ${device.desktop} {
    display: flex;
    margin-top: ${spacing(4)};
  }
`

export const StyledBlockquote = styled(Blockquote)`
  display: none;

  @media ${device.tabletLand}, ${device.desktop} {
    display: flex;
  }
`
