import styled from 'styled-components'
import { spacing, device } from '../../../utils/mixins'

export const StyledMapContainer = styled.div`
  margin-top: ${spacing(3)};
  height: 180px;
  flex: 0 0 100%;
  max-width: 100%;

  @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
    height: 360px;
  }

  svg {
    height: 32px;
    width: 32px;
  }
`
