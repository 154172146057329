import React from 'react'
import { StyledContactsFormContainer } from './styledComponents'
import FormBody from './form-body'

const Form = () => {
  return (
    <StyledContactsFormContainer>
      <h3>Бесплатная консультация по вопросам каркасного домостроения</h3>
      <FormBody />
    </StyledContactsFormContainer>
  )
}

export default Form
