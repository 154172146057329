import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledContainer,
  StyledBlockquote,
  StyledAuthor,
} from './styledComponents'

const Info = ({ title, subtitle, phones, emails, blockquote, ...rest }) => {
  return (
    <StyledContainer {...rest}>
      <h1 className='title'>{title}</h1>

      <p className='subtitle'>{subtitle}</p>

      <ul className='phones-list'>
        {phones.map(phone => (
          <li key={phone}>{phone}</li>
        ))}
      </ul>

      {emails.map(email => (
        <a className='email' href={`mailto:${email}`} key={email}>
          {email}
        </a>
      ))}

      <StyledBlockquote title={blockquote.title} subtitle={blockquote.text} />
      <StyledAuthor
        avatar={blockquote.author.avatar.mediaFile.publicURL}
        name={blockquote.author.name}
        position={blockquote.author.position}
      />
    </StyledContainer>
  )
}

Info.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  phones: PropTypes.arrayOf(PropTypes.string).isRequired,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  blockquote: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    author: PropTypes.shape({
      name: PropTypes.string,
      position: PropTypes.string,
      avatar: PropTypes.object,
    }),
  }),
}

export default Info
