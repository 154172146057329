import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Container from '../components/container'
import { Form, ModalForm, Info, GoogleMap } from '../components/contacts'
import SEO from '../components/seo'
import { StyledButton } from '../components/contacts/styledComponents'

export const query = graphql`
  query ContactsQuery {
    strapiGraphql {
      contact {
        pageSeo {
          description
          keywords
          title
        }
        title
        subtitle
        phones {
          value
        }
        emails {
          value
        }
        mapCoordinates {
          latitude
          longitude
        }
        blockquote {
          title
          text
          author {
            position
            name
            avatar {
              url
              mediaFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

export default function Contacts({ data }) {
  const content = data.strapiGraphql.contact

  const [openModal, setOpenModal] = useState(false)
  const handleModalState = () => setOpenModal(v => !v)

  const pageSeo = content.pageSeo
  const { title, subtitle, emails, phones, blockquote } = content
  const { latitude, longitude } = content.mapCoordinates

  return (
    <Layout>
      <SEO {...pageSeo} />

      <Container direction='row' wrap='wrap'>
        <Info
          title={title}
          subtitle={subtitle}
          phones={phones.map(({ value }) => value)}
          emails={emails.map(({ value }) => value)}
          blockquote={blockquote}
        />

        <Form />

        <StyledButton type='button' onClick={handleModalState}>
          Записаться на бесплатную консультацию
        </StyledButton>

        <GoogleMap lat={latitude} lng={longitude} />
      </Container>

      <ModalForm open={openModal} onClose={handleModalState} />
    </Layout>
  )
}
