import styled from 'styled-components'
import { spacing, device } from '../../../utils/mixins'
import Button from '../../button'
import { Checkbox, DropZone, Label } from '../../form-controls'

export const StyledContactsFormContainer = styled.div`
  display: none;
  margin-top: ${spacing(2)};

  @media ${device.tabletLand}, ${device.desktop} {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
    order: 3;
    margin-top: ${spacing(3)};
    padding-left: ${spacing(2)};

    border: 4px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    padding: ${spacing(4)};

    h3 {
      margin: 0;
      padding-top: ${spacing(1)};
      position: relative;

      &::before {
        content: '';
        background-color: #9a6244;
        position: absolute;
        top: 0;
        left: 0;
        height: 3px;
        width: 94px;
      }
    }
  }
`

export const StyledButton = styled(Button)`
  margin-top: 20px;
  @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
    max-height: 40px;
    max-width: 180px;
  }
`

export const StyledDropZone = styled(DropZone)`
  @media ${device.tabletLand}, ${device.desktop} {
    margin-top: 0;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  label {
    align-items: flex-start;
  }
`

export const StyledDropZoneLabel = styled(Label)`
  margin-bottom: 4px;

  @media ${device.tablet}, ${device.tabletLand} {
    margin-bottom: 0;
    flex: 0 0 auto;
    max-width: 100%;
  }
`
