import styled from 'styled-components'
import { device, spacing } from '../../utils/mixins'
import Button from '../button'

export const StyledButton = styled(Button)`
  padding: ${spacing(1)};
  max-width: 420px;

  @media ${device.tabletLand}, ${device.desktop} {
    display: none;
  }
`
