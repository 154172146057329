import React from 'react'
import PropTypes from 'prop-types'
import { StyledBlockquote } from './styledComponents'

const Blockquote = ({ title, subtitle, ...rest }) => {
  return (
    <StyledBlockquote {...rest}>
      <h4>{title}</h4>
      <p>{subtitle}</p>
    </StyledBlockquote>
  )
}

Blockquote.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default Blockquote
