import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalHeader } from '../../modal'
import FormBody from './form-body'

const ModalForm = ({ onClose, open }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <ModalHeader onClose={onClose}>
          Бесплатная консультация по вопросам каркасного домостроения
        </ModalHeader>
        <FormBody onClose={onClose} />
      </ModalBody>
    </Modal>
  )
}

ModalForm.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ModalForm
