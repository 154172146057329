import styled from 'styled-components'
import { spacing } from '../../utils/mixins'

export const StyledBlockquote = styled.blockquote`
  display: flex;
  flex-direction: column;
  margin: ${spacing(3, 0, 0)};
  padding: 0;

  h4 {
    font: 400 24px/30px Tenor, sans-serif;
    margin: 0;
  }

  p {
    font: 400 14px/30px Geometria, sans-serif;
    margin: ${spacing(2, 0, 0)};
  }
`
