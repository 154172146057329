import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  FormRow,
  Label,
  MaskedInput,
  Input,
  Textarea,
  Form,
  ErrorMessage,
} from '../../form-controls'
import {
  StyledButton,
  StyledDropZone,
  StyledCheckbox,
  StyledDropZoneLabel,
} from './styledComponents'
import { PHONE_MASK } from '../../form-controls/constants'
import useRequest from '../../../hooks/useRequest'
import { objectToFormData } from '../../../utils/formData'

const DEFAULT_DROP_ZONE_TEXT = 'Выберите файл'
const ERROR_MESSAGE = 'Введенные вами данные некорректны!'

const validateFormData = ({ phone, comment, policyAccepted }) => {
  const validPhone = phone && phone.replace('_', '').length === 18
  return validPhone && comment && policyAccepted
}

const FormBody = ({ onClose }) => {
  const recaptchaRef = useRef()

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [comment, setComment] = useState('')
  const [policyAccepted, setPolicyAccepted] = useState(false)

  const [dropZoneText, setDropZoneText] = useState(DEFAULT_DROP_ZONE_TEXT)
  const [file, setFile] = useState(null)

  const [errorMessage, setErrorMessage] = useState('')

  const resetForm = useCallback(() => {
    setName('')
    setPhone('')
    setComment('')
    setPolicyAccepted(v => !v)
    setFile(null)
  }, [])

  const handleNameChange = event => setName(event.target.value)
  const handlePhoneChange = event => setPhone(event.target.value)
  const handleFileDrop = file => setFile(file)
  const handlePolicyAcceptanceChange = () => setPolicyAccepted(v => !v)

  const { loading, error, data, makeRequest } = useRequest('/consultation', {
    method: 'POST',
    skip: true,
  })

  const handleSubmit = async event => {
    event.preventDefault()

    const isValidForm = validateFormData({ phone, comment, policyAccepted })
    if (!isValidForm) {
      setErrorMessage(ERROR_MESSAGE)
      return
    }

    const token = await recaptchaRef.current.executeAsync()
    recaptchaRef.current.reset()

    const formData = objectToFormData(
      { name, phone, comment, token },
      file?.length ? file[0] : null,
    )
    makeRequest({ data: formData })
  }

  useEffect(() => {
    setDropZoneText(file ? file[0].name : DEFAULT_DROP_ZONE_TEXT)
  }, [file])

  useEffect(() => {
    let timer
    if (errorMessage) {
      timer = setTimeout(() => setErrorMessage(''), 4000)
    }
    if (timer) {
      return () => clearTimeout(timer)
    }
  }, [errorMessage])

  useEffect(() => {
    if (data) {
      resetForm()
      onClose()
    }
  }, [data])

  return (
    <>
      <Form>
        <FormRow>
          <Label htmlFor='name'>Имя</Label>
          <Input
            id='name'
            type='text'
            value={name}
            onChange={handleNameChange}
          />
        </FormRow>

        <FormRow>
          <Label htmlFor='phone'>Телефон</Label>

          <MaskedInput
            id='phone'
            type='tel'
            value={phone}
            onChange={handlePhoneChange}
            mask={PHONE_MASK}
            alwaysShowMask
            required
          />
        </FormRow>

        <FormRow direction='column'>
          <Label htmlFor='comment'>Комментарий</Label>
          <Textarea
            id='comment'
            value={comment}
            onChange={e => setComment(e.target.value)}
            required
          />
        </FormRow>

        <FormRow>
          <StyledCheckbox
            id='policy'
            checked={policyAccepted}
            onChange={handlePolicyAcceptanceChange}
            required
          >
            <span>
              Даю согласие на обработку личных данных согласно&nbsp;
              <a href='/policy' target='_blank'>
                политике конфиденциальности
              </a>
            </span>
          </StyledCheckbox>
        </FormRow>

        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <StyledButton type='submit' loading={loading} onClick={handleSubmit}>
          Отправить заявку
        </StyledButton>
      </Form>

      <ReCAPTCHA
        sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
        size='invisible'
        ref={recaptchaRef}
      />
    </>
  )
}

FormBody.propTypes = {
  onClose: PropTypes.func,
}

FormBody.defaultProps = {
  onClose: () => {},
}

export default FormBody
