import styled from 'styled-components'
import { spacing } from '../../utils/mixins'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => (props.spacing ? spacing(props.spacing) : 0)};
  line-height: 15px;

  img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }
`

export const StyledText = styled.div`
  margin-left: ${spacing(2)};

  .name {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }

  .position {
    color: rgba(34, 34, 34, 0.8);
    font-size: 10px;
    margin: ${spacing(1 / 2, 0, 0)};
  }
`
