import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledText } from './styledComponents'

const Author = ({ name, position, avatar, ...rest }) => {
  return (
    <StyledContainer {...rest}>
      <img src={avatar} alt='avatar' />
      <StyledText>
        <h5 className='name'>{name}</h5>
        <span className='position'>{position}</span>
      </StyledText>
    </StyledContainer>
  )
}

Author.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
}

export default Author
